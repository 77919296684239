html{
	overscroll-behavior: contain;
}
body{
	overscroll-behavior: contain;
}

#BabylonCanvas {
	touch-action: none;
}
#ExteriorBabylonWrapper {
	position: relative;
	top: 50%;
    left: 50%;
	min-height: 100%;
    min-width: 100%;
	background-color: black;
	display: flex;
	justify-content: center;
	align-items: center;
	-o-object-fit: cover;
    object-fit: cover;
	-ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); 
}
#ImageTurntableWrapper
{
	overflow: hidden;
	position: absolute;
	z-index: -1;
	top: 0%;
	left: 0%;
	min-height: 100%;
    min-width: 100%;
}

.ExteriorOrbitImage {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
	-o-object-fit: cover;
    object-fit: cover;
}

.CenteredLoadingLogo {
    position: absolute;
    margin: auto;
    top: 50%;
    left: 50%;
    z-index: 99000;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.OverflowHiddenRoot {
    position: absolute;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
	user-select: none;
}
.ReactUIContainer {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}
.overflowHidden {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
}
.LoadingBackground {
    position: absolute;
    z-index: 9000;
}
.ExteriorUnitPage {
    position: absolute;
    z-index: 90;
}
.fadeTransition {
    opacity: 0;
    transition: opacity 2s ease-out;
}